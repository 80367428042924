import router from '@/router';
import axios, { AxiosRequestConfig, Method } from 'axios';

// 定义接口
interface PendingType {
  url?: string;
  method?: Method;
  params: any;
  data: any;
  cancel: Function;
}

// 取消重复请求
const pending: Array<PendingType> = [];
const CancelToken = axios.CancelToken;

const instance = axios.create({
  timeout: 1000000,
  baseURL: process.env.VUE_APP_HOST_URL,
  withCredentials: true
});

// 移除重复请求
const removePending = (config: AxiosRequestConfig) => {
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    // 当前请求在数组中存在时执行函数体
    if (
      list.url === config.url &&
      list.method === config.method &&
      JSON.stringify(list.params) === JSON.stringify(config.params) &&
      JSON.stringify(list.data) === JSON.stringify(config.data)
    ) {
      // 执行取消操作
      list.cancel('操作太频繁，请稍后再试');
      // 从数组中移除记录
      pending.splice(item, 1);
    }
  }
};

// 添加请求拦截器
instance.interceptors.request.use(
  (request) => {
    removePending(request);
    request.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    request.cancelToken = new CancelToken((c) => {
      pending.push({ url: request.url, method: request.method, params: request.params, data: request.data, cancel: c });
    });

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const code = response.status;
    if ((code >= 200 && code < 300) || code == 304) {
      const data = response.data;
      if (data.code == 1) {
        return Promise.resolve(response.data);
      } else if (data.code == 2002) {
        alert(data.msg);
        router.replace('/');
        return Promise.reject(response);
      } else {
        // alert(data.msg);
        return Promise.resolve(response);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.indexOf(502) >= 0) {
      alert('服务器重启中');
      return Promise.reject(error);
    }
    alert('网络出小差,请稍后尝试!');
    return Promise.reject(error);
  }
);

export { instance };
