import { AxiosRequestConfig } from 'axios';

import { IResponse } from '@/typing';
import { instance } from './axios';

class Mutation {
  async get(url: string, config: AxiosRequestConfig = {}) {
    return await instance.get<any, IResponse>(url, config);
  }

  async post(url: string, data: any = null, config?: AxiosRequestConfig) {
    return await instance.post<any, IResponse>(url, data, config);
  }

  async delete(url: string, config: AxiosRequestConfig = {}) {
    return await instance.delete<any, IResponse>(url, config);
  }

  async put(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    return await instance.put<any, IResponse>(url, data, config);
  }
}

let _http: Mutation;

const _HTTP = () => {
  if (!_http) {
    _http = new Mutation();
  }
  return _http;
};

export const Http = _HTTP();
